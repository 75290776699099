<template>
  <div id="page-shop_items">
    <h5 class="m-4">{{$t('AUTH.MENU.ORDERS')}}</h5>

    <ShopOrderTable
      ref="shop_orders_table"
      :shop_orders="shop_orders"
      @shop_order_selected="shop_order_selected"
    />  
    
  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import ShopOrderTable from '@/view/pages/ml/shop_order/ShopOrderTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ShopOrdersPage',
  mixins: [ toasts ],
  components: {
    ShopOrderTable,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },
  mounted() {
    
  },
  data() {
    return {
      shop_orders: [],
      selected_item: {}
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadPayments(newValue);
      }
    },
  },
  methods: {
    

  }
};
</script>
